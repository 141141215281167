<template>
  <div data-cy="template-manager">
    <v-slide-y-reverse-transition>
      <t-alert data-cy="alert" v-if="message" :message="message" :type="type"/>
    </v-slide-y-reverse-transition>
    <v-card>
      <v-card-title class="primary"><h3 class="white--text">Live Comment Template Manager</h3></v-card-title>
      <v-container grid-list-xl fluid>
        <v-layout row justify-start align-space-between>
          <!-- Comment List -->
          <v-flex xs3>
            <v-layout column justify-space-between fill-height>
              <!-- Search -->
              <v-flex shrink>
                <v-text-field
                  v-model="search"
                  label="Search..."
                  hide-details
                  prepend-icon="fal fa-search"
                  data-cy="template-search-field"
                ></v-text-field>
              </v-flex>
              <!-- List -->
              <v-flex>
                <v-list style="height: 275px; overflow-y: scroll !important;">
                  <v-fade-transition group mode="out-in" leave-absolute>
                    <v-progress-linear key="loading" v-if="loadingTemplates" indeterminate color="purple"></v-progress-linear>
                    <v-list-tile v-else-if="templates.length === 0" data-cy="no-template-tile" key="none">
                      <v-list-tile-content>
                        <v-list-tile-title>Create a template.</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <template v-else v-for="(template, index) in filteredTemplates">
                      <v-list-tile
                        v-ripple
                        :key="template.name"
                        :class="{ 'light-blue lighten-5': template.name === activeTemplateName }"
                        :data-cy="`template-tile-${index}`"
                        @click="setActiveTemplate(template)"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title>{{ template.name }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-divider v-if="index + 1 < templates.length" :key="index"/>
                    </template>
                  </v-fade-transition>
                </v-list>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-layout row>
                  <v-btn
                    color="error"
                    :disabled="!activeTemplateName"
                    data-cy="confirm-delete-template-button"
                    @click="confirmDelete"
                  >Delete</v-btn>
                  <v-btn
                    key="create"
                    color="primary"
                    data-cy="create-template-button"
                    @click="startTemplateCreation"
                  >Create</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Edit Comment -->
          <v-flex xs9>
            <v-layout column justify-space-between>
              <v-flex style="height: 85px;">
                <v-layout row>
                  <v-flex shrink>
                    <v-fade-transition mode="out-in">
                      <v-text-field
                        v-if="creatingNew || editTemplate"
                        v-model="activeTemplateName"
                        box
                        label="Template Name"
                        id="comment-name-field"
                        :rules="[e => !e.includes(' ') || 'Must not include spaces']"
                        data-cy="template-name-field"
                        @keydown.tab.prevent="focusTemplate"
                      ></v-text-field>
                      <v-btn data-cy="edit-template-button" v-else-if="!editTemplate" :disabled="!activeTemplateName" color="warning" @click="changeTemplate"><v-icon left color="white">fal fa-pencil</v-icon>Edit</v-btn>
                    </v-fade-transition>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex shrink>
                    <v-btn data-cy="cancel-button" :disabled="!editTemplate && !creatingNew" flat @click="cancel"><v-icon left>fal fa-times</v-icon>Cancel</v-btn>
                    <v-btn data-cy="save-button" id="save-button" :loading="savingComment" :disabled="!editTemplate && !creatingNew" color="primary" flat @click="save"><v-icon color="primary" left>fal fa-save</v-icon>Save</v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-textarea
                  v-model.trim="activeTemplateComment"
                  box
                  rows="12"
                  no-resize
                  id="commentEdit"
                  persistent-hint
                  :hint="hint"
                  :disabled="!editTemplate && !creatingNew"
                  :label="`${creatingNew ? 'New' : 'Edit'} Comment`"
                  data-cy="comment-text-area"
                  @keydown.tab.prevent="focusSave"
                >
                  <template v-slot:message="{ message, key }">
                    <span :key="key" v-html="message"/>
                  </template>
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <span class="primary--text bold">Manage templates here, use them by typing <span class="emphasis">/</span> in any text box!</span>
        <v-spacer/>
        <v-btn data-cy="close" @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
    <!-- Deletion Confirmation Dialog -->
    <v-dialog
      lazy
      persistent
      v-model="showDialog"
      width="50%"
    >
      <v-card data-cy="delete-template-card">
        <v-card-title primary-title>
          <span class="title">Confirm deletion</span>
        </v-card-title>
        <v-card-text class="pb-4">
          <span>Are you sure you want to delete comment template <span data-cy="template-to-delete-name" class="emphasis">{{ templateToDelete }}</span>?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            data-cy="cancel-delete-button"
            @click="cancelDelete"
          >Cancel</v-btn>
          <v-btn
            color="error"
            :loading="deletingComment"
            data-cy="delete-template-button"
            @click="deleteTemplate"
          >Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mutateLiveComments from '../../api/graphql/mutationsJS/mutateLiveComments'
import { GET_LIVE_COMMENTS } from '@/api/graphql/Constants/Profile'
import tAlert from '@/components/notifications/tAlert.vue'
export default {
  name: 'liveCommentSettings',
  components: {
    't-alert': tAlert
  },
  mixins: [mutateLiveComments],
  computed: {
    matchedTemplateIndex () {
      return this.templates.findIndex(t => {
        return t?.name === this.template?.name
      })
    },

    filteredTemplates () {
      if (this.templates.length === 0) return []
      return this.templates.filter(t => t.name.indexOf(this.search || '') > -1)
    },

    noEditionsMade () {
      return !this.template ||
        (this.activeTemplateComment === (this.template?.comment ?? this.activeTemplateComment) &&
        this.activeTemplateName === (this.template?.name ?? this.activeTemplateName))
    },

    activeTemplateName: {
      get () {
        return this.activeTemplate?.name ?? ''
      },
      set (value) {
        this.message = ''
        this.activeTemplate.name = value
      }
    },

    activeTemplateComment: {
      get () {
        return this.activeTemplate?.comment ?? ''
      },
      set (value) {
        this.message = ''
        this.activeTemplate.comment = value
      }
    },

    user () {
      return this.$store.getters['profile/user']
    }
  },
  watch: {
    activeTemplateName: function (value) {
      this.hasSpace = /\s/.test(value)
    }
  },
  data () {
    return {
      /* templates: [
        { name: 'Test', comment: 'This is a test comment' },
        { name: 'Multi line', comment: 'This is a \nmultiline \ncomment' },
        { name: 'fancy', comment: '[this] is a fancy [test] [comment] where you can enter [in] info [uber] super fancy [like]' }
      ], */
      templates: [],
      search: '',
      template: null,
      editTemplate: false,
      activeTemplate: { name: '', comment: '' },
      newTemplateName: '',
      templateToDelete: '',
      showDialog: false,
      loadingTemplates: false,
      noTemplates: false,
      hasSpace: false,
      selectHint: '<span class="emphasis">Select a template first!</span>',

      savingComment: false,
      savingName: false,
      deletingComment: false,
      creatingNew: false,
      hint: '',
      message: '',
      type: 'info'
    }
  },
  apollo: {
    templates: {
      query: GET_LIVE_COMMENTS,
      variables () {
        return {
          id: this.user.id
        }
      },
      update: function (data) {
        if (data.templates.length === 0) {
          this.noTemplates = true
        }
        this.loadingTemplates = false
        return data.templates
      },
      watchLoading (isLoading) {
        this.loadingTemplates = isLoading
      }
    }
  },
  methods: {
    changeTemplate () {
      this.editTemplate = true
    },

    setActiveTemplate (template) {
      this.activeTemplate = template
      this.editTemplate = false
    },

    createErrorSnackbar (error) {
      this.savingName = false
      this.savingComment = false
      this.deletingComment = false
      console.log(error)
      this.message = error.message || error
      this.type = 'error'
    },

    cancel () {
      this.editTemplate = false
      this.activeTemplate = { name: '', comment: '' }
      this.creatingNew = false
      this.hint = ''
      this.message = ''
    },

    save () {
      if (this.activeTemplateComment !== '' && this.activeTemplateName !== '') {
        this.creatingNew && this.createTemplate()
        this.editTemplate && this.saveEditions()
      } else {
        if (!this.activeTemplateName) {
          this.message = 'A template needs to have a name.'
          this.type = 'error'
        } else {
          this.message = 'A template needs to have content.'
          this.type = 'error'
        }
      }
    },

    async saveEditions () {
      this.savingComment = true
      try {
        const template = {
          id: this.activeTemplate.id,
          name: this.activeTemplateName,
          template: this.activeTemplateComment
        }
        await this.updateCommentTemplate(template)
        // this.template.comment = this.activeTemplateComment
        // this.template.name = this.activeTemplateName
        this.message = 'Saved Editions!'
        this.type = 'success'
        this.editTemplate = false
      } catch (error) {
        this.createErrorSnackbar(error)
      } finally {
        this.savingComment = false
      }
    },

    startTemplateCreation () {
      this.creatingNew = true
      this.template = null
      this.activeTemplate = { name: '', comment: '' }
      this.hint = 'Use the <span class="emphasis">brackets: [ ]</span> to create ' +
        '<span style="color: #4D148C; text-decoration: underline;">jump points</span> in your comment.'
      setTimeout(function () {
        document.getElementById('comment-name-field')?.focus()
      }, 600)
    },

    focusTemplate () {
      document.getElementById('commentEdit')?.focus()
    },

    focusSave () {
      document.getElementById('save-button')?.focus()
    },

    async createTemplate () {
      this.savingComment = true
      try {
        const template = {
          name: this.activeTemplateName,
          template: this.activeTemplate.comment
        }
        const newTemplate = await this.createCommentTemplate(template)
        this.templates.push(newTemplate)
        this.activeTemplate = null
        this.creatingNew = false
        this.hint = ''
        this.message = 'Saved new template'
        this.type = 'success'
      } catch (error) {
        this.createErrorSnackbar(error)
      } finally {
        this.savingComment = false
      }
    },

    confirmDelete () {
      this.templateToDelete = this.activeTemplate.name
      this.showDialog = true
    },

    cancelDelete () {
      this.templateToDelete = null
      this.showDialog = false
    },

    async deleteTemplate () {
      this.deletingComment = true
      try {
        await this.deleteCommentTemplate(this.activeTemplate.id, 'deletingComment')
        this.activeTemplate = null
        this.templates.splice(this.matchedTemplateIndex, 1)
        this.template = null
        this.templateToDelete = null
        this.showDialog = false
        this.deletingComment = false
      } catch (error) {
        this.createErrorSnackbar(error)
      }
    }
  }
}
</script>
