<template>
  <div
    id="action-bar"
    :class="{ 'action-bar-pulled': !extended, 'action-bar-extended': extended }"
  >
    <!-- Comments Container -->
    <v-container grid-list-xs px-1 pb-2 fluid>
      <!-- Larger Device Comment Select -->
      <v-layout column justify-start align-space-between fill-height>
        <v-flex xs2>
          <v-layout row justify-start align-center nowrap v-if="$vuetify.breakpoint.mdAndUp">
            <v-flex
              shrink
              v-for="(comment) in shownCommentList"
              :key="comment.label"
            >
              <v-btn
                active-class="active"
                small
                :dark="darkMode"
                :disabled="!comment.show"
                @click="setActiveComment(comment)"
                :class="{ active: isActiveComment(comment) }"
              >{{ comment.label }}</v-btn>
            </v-flex>
            <v-slide-y-reverse-transition>
              <v-flex xs5 v-if="!commentList.id" style="height: 100%;">
                <span class="comment-alert ml-2">Select a row to see notes</span>
              </v-flex>
            </v-slide-y-reverse-transition>
          </v-layout>
          <!-- Small Device Comment Select -->
          <v-layout row nowrap justify-start align-center v-else>
            <v-flex xs4 ml-2>
              <v-select
                v-model="activeCommentName"
                :items="shownCommentList"
                label="Comment"
                hide-details
                solo
                item-text="label"
                return-object
                @input="setActiveComment"
              />
            </v-flex>
            <v-slide-y-reverse-transition>
              <v-flex xs7 v-if="!commentList.id" shrink pt-2>
                <span class="comment-alert ml-2 mt-3">Select a row to see notes</span>
              </v-flex>
            </v-slide-y-reverse-transition>
          </v-layout>
        </v-flex>
        <v-flex xs10 py-2 pl-2>
          <v-layout row justify-start align-space-between>
            <v-flex xs7>
                <!-- <v-textarea> -->
                <live-comment-area
                  save-button-id="save-comment-button"
                  :comment="commentText"
                  :readonly="readonly"
                  id="live-comment-area"
                  @updateComment="commentText = $event">
                  <template v-slot:append v-if="activeComment.editable && readonly">
                    <v-btn
                      icon
                      :disabled="!commentList.id"
                      small
                      @click="readonly = !readonly"
                    >
                      <v-tooltip v-if="readonly" top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">fal fa-pencil</v-icon>
                        </template>
                        <span>Switch to edit mode</span>
                      </v-tooltip>
                    </v-btn>
                  </template>
                  <template v-slot:label>
                    <span>{{ activeComment.label }}</span>
                  </template>
                </live-comment-area>
            </v-flex>
            <!-- Buttons -->
            <v-flex xs5>
              <v-layout row justify-start align-start>
                <v-flex xs3>
                  <v-layout column justify-start align-start>
                    <!-- Comment Template Editor -->
                    <v-btn
                      large
                      title="Edit Comment Templates"
                      data-cy="open-comment-template-manager"
                      @click="editCommentTemplates"
                    >
                      <v-icon color="blue">fas fa-comment-edit</v-icon>
                    </v-btn>
                    <!-- Save -->
                    <v-btn
                      large
                      id="save-comment-button"
                      title="Save"
                      @click="save()"
                      :dark="darkMode"
                      :disabled="!unsavedComment"
                    >
                      <v-icon
                        color="successAlert"
                        v-if="!saving"
                      >{{ classType }} fa-save
                      </v-icon>
                      <v-progress-circular
                        v-if="saving"
                        color="blue"
                        indeterminate
                        size="20"
                      />
                    </v-btn>
                    <!-- Cancel -->
                    <v-btn
                      large
                      :dark="darkMode"
                      :disabled="readonly"
                      @click="cancel()"
                    >
                      <v-icon color="error">{{ classType }} fa-ban</v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="showDialog"
      lazy
      persistent
      max-width="900px"
    >
      <live-comment-settings v-if="showDialog" @close="showDialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import liveCommentArea from '../templates/liveCommentArea'
import liveCommentSettings from '../settings/liveCommentSettings'
export default {
  name: 'ActionBar',
  components: {
    'live-comment-area': liveCommentArea,
    'live-comment-settings': liveCommentSettings
  },
  props: {
    commentList: {
      type: Object,
      required: true
    }
  },
  computed: {
    extended () { // status of the action bar (if it is extended out or not)
      return this.$store.state.grid.extended
    },

    theme () {
      return this.$store.state.profile.theme
    },

    darkMode () {
      return this.theme === 'dark'
    },

    refresh () {
      return this.$store.state.data.refresh
    },

    unsavedComment () {
      return this.commentText !== this.activeComment.text
    },

    shownCommentList () {
      const list = []
      for (const commentObj in this.commentList) {
        if (commentObj !== 'id') {
          list.push(this.commentList[commentObj])
        }
      }
      return list
    },

    saving: {
      get () {
        return this.$store.state.data.saving
      },
      set (newValue) {
        this.$store.dispatch('data/changeSaving', { bool: newValue })
      }
    }
  },
  watch: {
    refresh: function (newValue) {
      if (newValue && this.commentList.lock_reason) {
        this.commentList.lock_reason.show = false
      }
    },
    activeComment: {
      deep: true,
      handler: function (value) {
        this.commentText = value.text
      }
    },
    commentList: {
      deep: true,
      handler: function (value) {
        if (value.id !== this.oldId) {
          this.readonly = true
        }
        this.oldId = value.id
      }
    }
  },
  data () {
    return {
      activeComment: '',
      activeCommentName: '',
      commentText: '',
      showCommentHint: true,
      readonly: true,
      oldId: '',
      showDialog: false,

      classType: 'fal '
    }
  },
  methods: {
    editCommentTemplates () {
      this.showDialog = true
    },

    save () {
      this.activeComment.text = this.commentText
      this.commentText = ''
      this.$emit('saveComments', this.activeComment)
      this.readonly = true
    },

    cancel () {
      this.commentText = this.activeComment.text
      this.readonly = true
    },

    maybeMakeEditable () {
      if (!this.showCommentHint) {
        this.readonly = false
      }
    },

    setActiveComment (comment) {
      if (this.unsavedComment) {
        this.$store.dispatch('notifications/createSnackbar', {
          message: 'Save or cancel comment changes to switch views',
          color: 'warning',
          timeout: 4000,
          canClose: false,
          top: false
        })
      } else {
        this.activeComment = comment
        this.commentText = comment.text
        this.readonly = true
      }
    },

    isActiveComment (comment) {
      return this.activeComment.label === comment.label
    }
  },
  mounted () {
    this.activeComment = this.commentList[Object.keys(this.commentList)[0]]
    this.activeCommentName = this.activeComment.label
  }
}
</script>

<style>
  .comment-alert {
    color: #311B92;
    background-color: #f0f0f0;
    border-radius: 2px;
    padding: 0px 10px 0px 10px;
  }
</style>
