import gql from 'graphql-tag'
import errorHandling from '../errorHandling'
import { GET_LIVE_COMMENTS } from '@/api/graphql/Constants/Profile'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Creates a Live Comment Template
     * @param {Object} comment
     * @param {String} comment.name
     * @param {String} comment.template
     * @returns {Promise<Object>}
     */
    async createCommentTemplate (comment) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateCommentTemplate ($comment: Create__Profile_LiveTemplate__Input!) {
            template: Create__Profile_LiveTemplate (input: $comment) {
              id
              name
              comment: template
            }
          }`,
          variables: { comment: comment }
        })
        return response.data.template
      } catch (error) {
        throw new Error(error.message)
      }
    },

    /**
     * Updates a comment template
     * @param {Object} comment
     * @param {String} comment.id required
     * @param {String} comment.name
     * @param {String} comment.template
     * @returns {Promise<Object>}
     */
    async updateCommentTemplate (comment) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation UpdateCommentTemplate ($comment: Update__Profile_LiveTemplate__Input!) {
            template: Update__Profile_LiveTemplate (input: $comment) {
              id
              name
              comment: template
            }
          }`,
          variables: { comment: comment }
          // update: (store, { data: { template } }) => {
          //   const data = store.readQuery({
          //     query: GET_LIVE_COMMENTS,
          //     variables: { id: this.$store.getters['profile/user'].id }
          //   })
          //   const index = data.templates.findIndex(function (order) {
          //     return order.id === template.id
          //   })
          //   data.templates.splice(index, 1, template)
          //   store.writeQuery({ query: GET_LIVE_COMMENTS, variables: { id: this.$store.getters['profile/user'].id }, data: data })
          // }
        })
        return response.data.template
      } catch (error) {
        throw new Error(error.message)
      }
    },

    /**
     * Deletes a comment template
     * @param {String} id
     * @returns {Promise<Object>}
     */
    async deleteCommentTemplate (id) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation DeleteCommentTemplate ($id: ID!) {
            template: Delete__Profile_LiveTemplate (id: $id) {
              id
            }
          }`,
          variables: { id: id }
        })
        return response.data.template
      } catch (error) {
        const fixError = await this.errorHandler(error)
        if (fixError.retry) this.deleteCommentTemplate(id)
        else throw new Error('Could not delete comment template')
      }
    }
  }
}
